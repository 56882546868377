import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";


/* eslint-disable import/prefer-default-export */
export const useAuthContext = () => {
    const context =useContext(AuthContext)

    if(!context){
        throw Error("useAuthContext must be used inside a useAuthContext provider")
    }
    return context
}
