import React, { useState, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import {
  AGENT_PROFILE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  ADD_HOTEL_PAGE,
} from 'settings/constant';
import useLogout from 'library/hooks/useLogout';

export default function ProfileMenu({ avatar,user }) {
  let navigate = useNavigate();
  const { logout } = useLogout();
   const [state, setState] = useState(false);
  const handleDropdown = () => {
    setState(!state);
  };
  const closeDropdown = () => {
    setState(false);
  };
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));
  function handleLogout() {
    logout();
    navigate('/', { replace: true });
  }

  const menuItems = [
    {
      label: <NavLink to={AGENT_PROFILE_PAGE}>My Profile</NavLink>,
      key: 'view_profile',
    },
    {
      label: <NavLink to={ADD_HOTEL_PAGE}>Add Hotel</NavLink>,
      key: 'add_hotel',
    },
    {
      label: (
        <NavLink to={AGENT_ACCOUNT_SETTINGS_PAGE}>Settings</NavLink>
      ),
      key: 'account_setting',
    },
    { label: `Credits: ${user.credits} `,key:'credits'},

    {
      label: (
        <NavLink onClick={handleLogout}>Log Out</NavLink>
      ),
      key: 'log-out',
    },
  ];

  return (
    <div className="avatar-dropdown" ref={dropdownRef}>
      <div className="dropdown-handler" onClick={handleDropdown}>
        {avatar}
      </div>
      <Menu
        className={`dropdown-menu ${state ? 'active' : 'hide'}`}
        items={menuItems}
        onClick={closeDropdown}
      />
    </div>
  );
}
