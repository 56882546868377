import React from 'react';
import { NavbarSearchWrapper } from './Header.style';
import NavSearch from './NavSearch';

export default function NavbarSearch() {

  return (
    <NavbarSearchWrapper className="navbar_search">
      {/* <MapAutoComplete updateValue={(value) => updateValueFunc(value)} /> */}
        <NavSearch />
      {/* <FiSearch /> */}
    </NavbarSearchWrapper>
  );
}
