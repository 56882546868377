import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router";

const useLogout = () => {
  const { dispatch } = useAuthContext();
  const navigate=useNavigate()
  //remove user from local storage
  const logout = () => {
    localStorage.removeItem("user");
    dispatch({ type: "LOGOUT" });
    navigate('/auth/login')
  };
  return { logout };
};

export default useLogout