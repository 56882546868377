// context/FilterContext.js
import React, { createContext, useState } from 'react';

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filter, setFilter] = useState({});
  const [propertyType, setPropertyType] = useState([]);
  const [amenities, setAmenities] = useState([]);

  return (
    <FilterContext.Provider
      value={{
        filter,
        setFilter,
        propertyType,
        setPropertyType,
        amenities,
        setAmenities,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
